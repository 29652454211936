<template>
    <b-overlay :show="showLoader">
        <b-card class="footer-pages">
            <div style="font-size: 20px;">Nagrajevanje</div>
            <div class="d-flex justify-content-between my-3">
                <b-form-checkbox :disabled="!$hasPermissions($permissions.EditPages)" class="" slider v-model="active">Aktivna nagradna igra</b-form-checkbox>
                <b-badge v-if="active" variant="success">Aktivna</b-badge>
                <b-badge v-else variant="danger">Neaktivna</b-badge>    
            </div>
            
            <b-form-group class="mt-1" label="Naslov strani">
                <b-form-input :disabled="!$hasPermissions($permissions.EditPages)" class="mt-1" v-model="title"/>
            </b-form-group>

            <b-form-checkbox :disabled="!$hasPermissions($permissions.EditPages)" class="mt-5" slider v-model="external">Zunanji opis poteka nagrajevanja </b-form-checkbox>
            <b-form-group v-if="external" class="mt-3" label="Spletni naslov do zunanjega opisa nagrajevanja">
                <b-form-input :disabled="!$hasPermissions($permissions.EditPages)" class="mt-1" v-model="link"/>
            </b-form-group>
            <b-form-group v-else class="mt-1" label="Potek nagrajevanja">
                <TextAreaEditor :disabled="!$hasPermissions($permissions.EditPages)" class="mt-1" v-model="description_1"/>
            </b-form-group>

            <b-form-checkbox :disabled="!$hasPermissions($permissions.EditPages)" class="mt-5" slider v-model="external_terms">Zunanji pogoji sodelovanja v nagradni igri </b-form-checkbox>
            <b-form-group v-if="external_terms" class="mt-3" label="Spletni naslov do zunanjih pogojov">
                <b-form-input :disabled="!$hasPermissions($permissions.EditPages)" class="mt-1" v-model="link_terms"/>
            </b-form-group>
            <b-form-group v-else class="mt-2" label="Pogoji sodelovanja v nagradni igri">
                <TextAreaEditor :disabled="!$hasPermissions($permissions.EditPages)" class="mt-1" v-model="description_2"/>
            </b-form-group>

            <b-button @click="updateDescription" v-if="$hasPermissions($permissions.EditPages)">Posodobi</b-button>
        </b-card>
    </b-overlay>
</template>

<script>
    import { BCard, BFormGroup, BButton, BFormInput, BOverlay, BFormCheckbox, BBadge } from 'bootstrap-vue'
    import TextAreaEditor from '@/views/Components/TextAreaEditor'

    export default {
        components:{
            BCard,
            BFormGroup,
            TextAreaEditor,
            BButton,
            BFormInput,
            BOverlay,
            BFormCheckbox,
            BBadge
        },
        data() {
            return {
                showLoader: false,
                description_1: '',
                description_2: '',
                title: '',
                active:false,
                external:false,
                external_terms:false,
                link:'',
                link_terms:''
            }
        },
        methods:{
            async loadData() {
                this.showLoader = true
                try {
                    const res = await this.$http.get('/api/management/v1/pages/awarding')
                    if (res && res.data) {
                        this.description_1 = res.data.description_1
                        this.description_2 = res.data.description_2
                        this.title = res.data.title
                        this.active = res.data.active
                        this.external = res.data.external
                        this.external_terms = res.data.external_terms
                        this.link = res.data.link
                        this.link_terms = res.data.link_terms
                    }
                } catch (err) {
                    this.$printError('Prislo je do napake pri nalaganju podatkov')
                } finally {
                    this.showLoader = false
                }
            },
            async updateDescription() {
                this.showLoader = true
                try {
                    const payload = {
                        description_1: this.description_1,
                        title: this.title,
                        description_2 : this.description_2,
                        active : this.active,
                        external : this.external,
                        external_terms : this.external_terms,
                        link : this.link,
                        link_terms : this.link_terms
                    }
                    await this.$http.post('/api/management/v1/pages/awarding', payload)
                    this.$printSuccess('Uspesno posodobljeno')

                } catch (err) {
                    this.$printError('Prislo je do napake pri posodobitvi podatkov')
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;500;700;800&display=swap');

    .footer-pages li{
        font-family: 'Encode Sans', sans-serif !important;
    }
    .footer-pages p {
        font-family: 'Encode Sans', sans-serif !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
    }

    .footer-pages a {
        font-family: 'Encode Sans', sans-serif !important;
        color: #72A5D8;
        font-weight: 700;
    }

    .footer-pages h4 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 17px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }

    .footer-pages h1 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 1.3rem;
        font-weight: bold;
    }

    .footer-pages h2 {
        font-family: 'Encode Sans', sans-serif !important;
        font-size: 17px !important;
        font-weight: normal;
        padding-bottom: 15px;
        margin-bottom: 35px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }

    .footer-pages iframe {
        height: 330px !important;
        width: 600px !important;
        margin-left: auto;
        margin-right: auto;
    }
    @media only screen and (max-width: 992px) {
        .footer-pages iframe {
            height: 200px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
         }
    }
</style>
